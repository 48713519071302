<template>
  <b-container fluid>
    <b-row>
        <b-col md="12">
            <iq-card>
                <template v-slot:headerTitle>
                    <h4 class="card-title">{{ $t('externalTraining.routine') }}</h4>
                </template>
                <template v-slot:headerAction>
                    <router-link to="routine-list" :class="'btn btn-success text-light mr-2'">{{ $t('elearning_tpm.training_schedule') }} {{ $t('globalTrans.list') }}</router-link>
                    <b-button variant="primary" @click="pdfExportList" class="mr-2">
                        {{  $t('globalTrans.export_pdf') }}
                    </b-button>
                </template>
                <template v-slot:body>
                    <b-overlay :show="loadingState">
                        <div style="border: 2px solid;margin:10px;padding:10px">
                            <b-row v-if="headData.org_id">
                                <b-col>
                                    <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="headData.org_id">
                                        <template v-slot:projectNameSlot>
                                        {{ }}
                                        </template>
                                        {{ $t('externalTraining.routine') }}
                                    </list-report-head>
                                </b-col>
                            </b-row>
                            <b-row v-if="routines.length>0">
                                <b-col>
                                    <div class="text-center">
                                        <b-table-simple borderless hover small caption-top>
                                            <tbody>
                                                <tr>
                                                    <th style="width: 22%"  class="text-left" >{{ $t('elearning_iabm.circular_memo_no') }}</th>
                                                    <th class="text-center" style="width: 3%">:</th>
                                                    <td style="width: 23%" class="text-left">{{ headData.circular_memo_no }}</td>
                                                    <th style="width: 22%" class="text-left">{{ $t('elearning_iabm.batch_no') }}</th>
                                                    <th style="width: 3%" class="text-center" >:</th>
                                                    <td style="width: 25%" class="text-left" >{{ $i18n.locale === 'bn' ? headData.batch_bn : headData.batch }}</td>
                                                </tr>
                                                <tr>
                                                    <th style="width: 22%" class="text-left" >{{ $t('elearning_config.fiscal_year') }}</th>
                                                    <th class="text-center" style="width: 3%">:</th>
                                                    <td style="width: 25%" class="text-left">{{ $i18n.locale === 'bn' ? headData.fiscal_year_bn : headData.fiscal_year }}</td>
                                                    <th style="width: 22%"  class="text-left">{{ $t('elearning_config.training_type') }}</th>
                                                    <th style="width: 3%" class="text-center">:</th>
                                                    <td style="width: 25%" class="text-left">{{ $i18n.locale === 'bn' ? headData.training_type_bn : headData.training_type }}</td>
                                                </tr>
                                                <tr>
                                                    <th style="width: 22%"  class="text-left">{{ $t('elearning_config.training_category') }}</th>
                                                    <th style="width: 3%" class="text-center">:</th>
                                                    <td style="width: 25%" class="text-left">{{ $i18n.locale === 'bn' ? headData.training_category_bn : headData.training_category }}</td>
                                                    <th style="width: 22%"  class="text-left">{{ $t('elearning_config.training_title') }}</th>
                                                    <th style="width: 3%" class="text-center">:</th>
                                                    <td style="width: 25%" class="text-left">{{ $i18n.locale === 'bn' ? headData.training_title_bn : headData.training_title }}</td>
                                                </tr>
                                            </tbody>
                                        </b-table-simple>
                                    </div>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <slot v-if="routines.length>0">
                                        <div v-for="(routine,index) in routines" :key="index">
                                            <b-row class="pl-2 pr-2">
                                            <b-col lg="6" sm="6" class="text-left pl-10">
                                                <b>{{ $t('globalTrans.day') }}-{{$n(index+1)}}</b>
                                            </b-col>
                                            <b-col lg="6" sm="6"  class="text-right pr-10">
                                                <b>{{routine.date | dateFormat}} ({{dayGetName(routine.date)}})</b>
                                            </b-col>
                                            <b-col lg="12" sm="12">
                                                <table class="table tables-data" style="width:100%" border="1">
                                                    <thead class="thead">
                                                        <tr>
                                                        <b-th style="width:10%" class="text-center">{{ $t('globalTrans.time') }}</b-th>
                                                        <b-th style="width:10%" class="text-center">{{$t('elearning_config.topics_session_e')}}</b-th>
                                                        <b-th style="width:10%" class="text-center">{{$t('elearning_config.speaker_facilitator_e')}}</b-th>
                                                        <b-th style="width:10%" class="text-center">{{$t('elearning_config.vanue_name')}}</b-th>
                                                    </tr>
                                                    </thead>
                                                    <tr v-for="(schedule,index1) in routine.list" :key="index1">
                                                        <td class="text-center">{{schedule.start_time | time12FormateTraining}} - {{schedule.end_time | time12FormateTraining}}</td>
                                                        <td>
                                                            {{ $i18n.locale === 'bn' ? schedule.topics_session_bn : schedule.topics_session }}
                                                        </td>
                                                        <td class="text-center">
                                                            {{ $i18n.locale === 'bn' ? schedule.speaker_bn : schedule.speaker }}<slot v-if="schedule.trainer_id">{{ ',' }}</slot>
                                                            <template v-if="schedule.trainer_id">
                                                                <!-- <designation :item="schedule.personal_data.professional_info" type="panel"/>
                                                                <workplace :item="schedule.personal_data.professional_info" type="panel"/> -->
                                                                {{ getWorkPlace(schedule.personal_data.professional_info, 'panel') }}{{ getOfficeData(schedule.personal_data.professional_info, 'panel') }}
                                                            </template>
                                                        </td>
                                                        <td class="text-center">
                                                            {{ $i18n.locale === 'bn' ? schedule.venue_bn : schedule.venue }}
                                                        </td>
                                                    </tr>
                                                </table>
                                            </b-col>
                                            </b-row>
                                        </div>
                                    </slot>
                                    <slot v-else>
                                        <h4 class="text-center text-danger">{{$t('globalTrans.noDataFound')}}</h4>
                                    </slot>
                                </b-col>
                            </b-row>
                            <b-row>
                                <div class="col-md-12" id="form">
                                <!-- <fieldset class="pt-2 w-100"> -->
                                    <!-- <legend class="px-2 w-50 shadow-sm">{{$t('elearning_tpm.faculty_members_nata')}}</legend> -->
                                    <slot v-if="committee && routines.length>0">
                                    <div>
                                        <b-row class="pl-2 pr-2">
                                            <b-col lg="12" sm="12" class="text-left pl-10">
                                                <b>{{ $t('elearning_tpm.course_management_team_cmt') }}</b>
                                            </b-col>
                                            <b-col lg="12" sm="12">
                                                <table class="table tables-data" style="width:100%" border="1">
                                                <thead class="thead">
                                                    <tr>
                                                    <b-th style="width:10%" class="text-center">{{ $t('globalTrans.name') }}</b-th>
                                                    <b-th style="width:10%" class="text-center">{{$t('globalTrans.designation')}}</b-th>
                                                    <b-th style="width:10%" class="text-center">{{$t('globalUserData.mobile_no')}} / {{ $t('globalTrans.email') }}</b-th>
                                                    </tr>
                                                </thead>
                                                <tr v-for="(committee,index1) in committee.details" :key="index1">
                                                    <td class="text-center">{{ $i18n.locale === 'bn' ? committee.name_bn : committee.name_en }}</td>
                                                    <td class="text-center">
                                                    {{ $i18n.locale === 'bn' ? committee.designation_bn : committee.designation_en }}
                                                    </td>
                                                    <td class="text-center">
                                                    {{ ($i18n.locale === 'bn' ? '০' : '0') + $n(committee.mobile_no, { useGrouping: false }) }} <br>
                                                    {{ committee.email }}
                                                    </td>
                                                </tr>
                                                </table>
                                            </b-col>
                                        </b-row>
                                    </div>
                                    </slot>
                                </div>
                            </b-row>
                        </div>
                    </b-overlay>
                </template>
            </iq-card>
        </b-col>
    </b-row>
  </b-container>
</template>
<style>
.tables-data th, .tables-data td {
  padding: 0px 0px 0px 10px !important;
}
.tables-data {
  margin-bottom: 0px !important
}
</style>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { trainingScheduleList, trainingScheduleCommittee } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import ListReportHead from '@/components/custom/ExternalListReportHead.vue'
import ExportPdf from './export_pdf_list'
// import designation from '@/modules/training-e-learning-service/component/designation'
// import workplace from '@/modules/training-e-learning-service/component/workplace'
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        ListReportHead
    },
    data () {
        return {
          search: {
            org_id: 0,
            fiscal_year_id: 0,
            circular_memo_no: 0,
            training_type_id: 0,
            training_category_id: 0,
            training_title_id: 0,
            batch_no: '',
            registration_for: this.$store.state.ExternalUserIrrigation.panelObj.trainingType
          },
          headData: {
            fiscal_year: '',
            fiscal_year_bn: '',
            batch: '',
            batch_bn: '',
            circular_memo_no: ''
          },
          batchList: [],
          trainingTypeList: [],
          allBatchListData: [],
          circularLoading: false,
          loadingSearch: false,
          fiscal_year_id: '',
          routines: [],
          committee: [],
          trainingCategoryList: [],
          trainingTitleList: [],
          circularList: [],
          org_id: 0,
          trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
          allData: []
        }
    },
    computed: {
      ...mapGetters({
        authUser: 'Auth/authUser'
      }),
        fiscalYearList: function () {
            return this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.filter(item => item.status === 0)
        },
        allBatchList () {
            return [
                { value: 1, text: this.$i18n.locale === 'en' ? '1st' : '১ম', text_en: '1st', text_bn: '১ম' },
                { value: 2, text: this.$i18n.locale === 'en' ? '2nd' : '২য়', text_en: '2nd', text_bn: '২য়' },
                { value: 3, text: this.$i18n.locale === 'en' ? '3rd' : '৩য়', text_en: '3rd', text_bn: '৩য়' },
                { value: 4, text: this.$i18n.locale === 'en' ? '4th' : '৪র্থ', text_en: '4th', text_bn: '৪র্থ' },
                { value: 5, text: this.$i18n.locale === 'en' ? '5th' : '৫ম', text_en: '5th', text_bn: '৫ম' }
            ]
        },
      orgList: function () {
        return this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.filter(item => item.status === 0)
      },
      formTitle () {
          return (this.editItemId === 0) ? this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.update')
      }
    },
    async created () {
        this.loadData()
    },
    mounted () {
        core.index()
    },
    watch: {
    },
    methods: {
        getWorkPlace (item, type) {
            if (parseInt(item.profession_type) === 1 && item.not_here_office === 0) {
                return ',' + this.getOfficeName(item.office_id)
            } else if (parseInt(item.profession_type) === 1 && item.not_here_office === 1) {
                return ',' + ((this.$i18n.locale === 'bn' ? item.office_name_bn : item.office_name))
            } else if (item.not_here_office === 1) {
                return ',' + ((this.$i18n.locale === 'bn' ? item.office_name_bn : item.office_name))
            } else {
                return ''
            }
        },
        getOfficeName (id, type) {
            let office
            if (type === 'admin') {
                office = this.$store.state.commonObj.officeList.find(item => item.value === id)
            }
            if (type === 'panel') {
                office = this.$store.state.ExternalUserIrrigation.commonObj.officeList.find(item => item.value === id)
            }
            if (this.$i18n.locale === 'bn') {
                return office !== undefined ? office.text_bn : ''
            } else {
                return office !== undefined ? office.text_en : ''
            }
        },
        getOfficeData (item, type) {
            if (parseInt(item.not_here_designation) === 0 && parseInt(item.profession_type) === 1) {
                let desigObj
                if (type === 'admin') {
                desigObj = this.$store.state.commonObj.designationList.find(designation => designation.value === item.designation_id)
                }
                if (type === 'panel') {
                desigObj = this.$store.state.ExternalUserIrrigation.commonObj.designationList.find(designation => designation.value === item.designation_id)
                }
                if (typeof desigObj !== 'undefined') {
                return this.$i18n.locale === 'en' ? desigObj.text_en : desigObj.text_bn
                } else {
                return ''
                }
            } else {
                return this.$i18n.locale === 'en' ? item.designation_en : item.designation_bn
            }
        },
        dayGetName (value) {
            return moment(value).format('dddd')
        },
        loadData () {
            const params = {
                circular_memo_no: this.$route.query.circular_memo_no,
                batch_no: this.$route.query.batch_no
            }
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(trainingElearningServiceBaseUrl, trainingScheduleList, params).then(response => {
                if (response.success) {
                    this.routines = response.data
                    this.getCustomDataList(response.list[0])
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        getCustomDataList (data) {
            if (data) {
                const item = data
                const commiteeSearch = {
                    fiscal_year_id: item.fiscal_year_id,
                    org_id: item.org_id
                }
                RestApi.getData(trainingElearningServiceBaseUrl, trainingScheduleCommittee, commiteeSearch).then(response => {
                    if (response.success) {
                        this.committee = response.data
                        this.circularLoading = false
                    } else {
                        this.circularLoading = false
                    }
                })
                const orgObj = this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
                if (typeof orgObj !== 'undefined') {
                    this.headData.org = orgObj.text_en
                    this.headData.org_bn = orgObj.text_bn
                } else {
                    this.headData.org = ''
                    this.headData.org_bn = ''
                }
                const trainingTitleObj = this.$store.state.ExternalUserTraining.traineeTranerObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
                if (typeof trainingTitleObj !== 'undefined') {
                    this.headData.training_title = trainingTitleObj.text_en
                    this.headData.training_title_bn = trainingTitleObj.text_bn
                } else {
                    this.headData.training_title = ''
                    this.headData.training_title_bn = ''
                }
                const trainingTypeObj = this.$store.state.ExternalUserTraining.traineeTranerObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
                if (typeof trainingTypeObj !== 'undefined') {
                    this.headData.training_type = trainingTypeObj.text_en
                    this.headData.training_type_bn = trainingTypeObj.text_bn
                } else {
                    this.headData.training_type = ''
                    this.headData.training_type_bn = ''
                }
                const trainingCategoryObj = this.$store.state.ExternalUserTraining.traineeTranerObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
                if (typeof trainingCategoryObj !== 'undefined') {
                    this.headData.training_category = trainingCategoryObj.text_en
                    this.headData.training_category_bn = trainingCategoryObj.text_bn
                } else {
                    this.headData.training_category = ''
                    this.headData.training_category_bn = ''
                }
                const batchObj = this.allBatchList.find(batch => batch.value === parseInt(item.batch_no))
                if (typeof batchObj !== 'undefined') {
                    this.headData.batch = batchObj.text_en
                    this.headData.batch_bn = batchObj.text_bn
                } else {
                    this.headData.batch = ''
                    this.headData.batch_bn = ''
                }
                const fiscalYearObj = this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
                if (typeof fiscalYearObj !== 'undefined') {
                    this.headData.fiscal_year = fiscalYearObj.text_en
                    this.headData.fiscal_year_bn = fiscalYearObj.text_bn
                } else {
                    this.headData.fiscal_year = ''
                    this.headData.fiscal_year_bn = ''
                }
                this.headData.circular_memo_no = item.circular_memo_no
                this.headData.org_id = item.org_id
            }
        },
        pdfExportList () {
            const reportTitle = this.$t('externalTraining.routine')
            ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', this.headData.org_id, reportTitle, this.headData, this, this.routines, this.committee)
        }
    }
}
</script>
